import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import CheckedCb from '../../images/checked-cb.svg';
import Umbrella from '../../images/umbrella.svg';

const ContactInfo = () => {
  const { site } = useStaticQuery(graphql`
    query {
      site: site {
        siteMetadata {
          contactNumber
        }
      }
    }
  `);

  return (
    <>
      <h3 className="fs-35 ganen-green-bold">
        Get a free quote and GANEN offers.
      </h3>

      <div className="fs-14">
        Call{' '}
        <a
          className="text-black-brown text-decoration-none"
          href={`tel:${site.siteMetadata.contactNumber}`}
        >{`${site.siteMetadata.contactNumber}`}</a>{' '}
        or fill out the form.
      </div>

      <div className="fs-14 mw-295 no-mw-xs">
        A GANEN Specialist will contact you on the number provided about GANEN
        offers.
      </div>

      <div className="mw-225 py-5 mx-auto mx-md-0">
        <div className="d-flex">
          <span>
            <CheckedCb height={38} fill="#a57e48" className="mr-3 mb-4" />
          </span>
          <span className="fs-20 ganen-green-bold d-inline-block">
            1 hour reply
          </span>
        </div>
        <div className="d-flex">
          <span>
            <CheckedCb height={38} fill="#a57e48" className="mr-3 mb-4" />
          </span>
          <span className="fs-20 ganen-green-bold d-inline-block">
            1 day quotation
          </span>
        </div>
        <div className="d-flex">
          <span>
            <CheckedCb height={38} fill="#a57e48" className="mr-3 mb-4" />
          </span>
          <span className="fs-20 ganen-green-bold d-inline-block">
            2 + 3 years onsite warranty*
          </span>
        </div>
      </div>

      <div className="d-flex mb-5">
        <span>
          <Umbrella height={40} className="mr-3 mb-5" color="#a57e48" />
        </span>
        <span className="fs-14 mw-300 d-inline-block">
          * Our Umbrella Programme is a 5-year warranty coverage on all your
          GANEN products that includes on-site servicing and replacements,
          giving you a worry-free experience with your ideal home automation
          solution.
        </span>
      </div>
    </>
  );
};

export default ContactInfo;
