import React from 'react';

import ContactUsForm from '../forms/contact-us';
import ContactUsInfo from './contact-us-info';

const ContactUs = ({ sectionClass }) => {
  return (
    <section className={`${sectionClass || ''}`}>
      <div className="py-5 contact-us">
        <div className="box-container row mx-0 mx-md-auto justify-content-md-center">
          <div className="col-12 col-md-5">
            <ContactUsInfo />
          </div>

          <div className="col-12 col-md-5">
            <ContactUsForm />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
