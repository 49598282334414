/* eslint-disable no-useless-escape */

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, FormGroup, Label, Input } from 'reactstrap';

import { navigate } from 'gatsby';

const ContactUsForm = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit, errors } = useForm();

  const encodeForFormSubmission = (data) => {
    try {
      // eslint-disable-next-line implicit-arrow-linebreak
      return Object.keys(data)
        .map(
          (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join('&');
    } catch (e) {
      console.log(e);
      return {};
    }
  };

  const onSubmit = (data) => {
    if (isLoading) return;

    setIsLoading(true);

    if (window && window !== null) {
      const url = window.location.href;

      if (url.indexOf('localhost') > -1) {
        console.log('Local test, contact us form not send.');
        console.log(data);
        setIsLoading(false);
        navigate('/thank-you');
        return;
      }
    }

    fetch(
      'https://9orbhwt94h.execute-api.us-east-1.amazonaws.com/prod/send-contact-us',
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encodeForFormSubmission(data),
      }
    )
      .then(() => {
        setIsLoading(false);
        navigate('/thank-you');
      })
      .catch((error) => {
        setIsLoading(false);
        // eslint-disable-next-line no-alert
        alert('Error encountered while submitting the form.');
        // eslint-disable-next-line no-console
        console.log(error);
      });

    setIsLoading(false);
  };

  return (
    <form
      noValidate
      name="contact"
      method="POST"
      action="#"
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormGroup>
        <Input
          type="text"
          name="fullName"
          id="fullName"
          placeholder="Full Name"
          innerRef={register({ required: true, minLength: 2 })}
          invalid={errors.fullName !== undefined}
          disabled={isLoading}
        />
      </FormGroup>
      <FormGroup>
        <Input
          type="text"
          name="phoneNumber"
          id="phoneNumber"
          placeholder="Phone Number"
          innerRef={register()}
          invalid={errors.phoneNumber !== undefined}
          disabled={isLoading}
        />
      </FormGroup>
      <FormGroup>
        <Input
          type="email"
          name="emailAddress"
          id="emailAddress"
          placeholder="Email Address"
          innerRef={register({
            required: true,
            pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
          })}
          invalid={errors.emailAddress !== undefined}
          disabled={isLoading}
        />
      </FormGroup>
      <FormGroup>
        <Input
          type="textarea"
          name="comment"
          id="comment"
          placeholder="Your Comment"
          invalid={errors.comment !== undefined}
          innerRef={register({ required: true })}
          disabled={isLoading}
        />
      </FormGroup>
      <FormGroup>
        <div className="fs-16 ganen-dark-2">
          I am interested in products for *
        </div>
        <FormGroup check>
          <Label check>
            <Input
              type="radio"
              name="product"
              value="Home"
              innerRef={register({ required: true })}
              disabled={isLoading}
            />
            Home
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input
              type="radio"
              name="product"
              value="Office"
              innerRef={register({ required: true })}
              disabled={isLoading}
            />
            Office
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input
              type="radio"
              name="product"
              value="Hotel"
              innerRef={register({ required: true })}
              disabled={isLoading}
            />
            Hotel
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input
              type="radio"
              name="product"
              value="Property Development"
              innerRef={register({ required: true })}
              disabled={isLoading}
            />
            Property Development
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input
              type="radio"
              name="product"
              value="Retail / Distribution"
              innerRef={register({ required: true })}
              disabled={isLoading}
            />
            Retail / Distribution
          </Label>
        </FormGroup>
        {errors.product !== undefined && (
          <span className="text-small text-danger">Select one product.</span>
        )}
      </FormGroup>
      <Button
        className="btn btn-ganen-brown rounded-5 text-white px-4"
        disabled={isLoading}
      >
        Get a Free Quote
      </Button>

      <FormGroup check className="row pt-4">
        <div className="fs-12 mw-400">
          In accordance with DNC and PDPA regulations, by submitting this form
          you are also giving us consent to contact you via phone
          (call/SMS/WhatsApp) and email with the information you have provided
          above.
        </div>
      </FormGroup>
    </form>
  );
};

export default ContactUsForm;
